import {NAV_TYPE_SIDE, SIDE_NAV_LIGHT} from 'constants/ThemeConstant'
import {env} from './EnvironmentConfig'

export const APP_NAME = 'Sherp.ai'
export const API_BASE_URL = env.API_ENDPOINT_URL
export const APP_PREFIX_PATH = '/app'
export const AUTH_PREFIX_PATH = '/auth'
export const DEFINITION_PREFIX_PATH = env.documentation.baseUrl
export const ADMIN_PREFIX_PATH = '/admin'
export const DEFAULT_PREFIX_PATH = '/info'

export const DATA_CONFIG = {
  earningColorChangeMutedValue: 2,
  earningColorChangeTopValue: 100,
  earningColorChangeBottomCpm: 4
}

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: 'fr',
  navType: NAV_TYPE_SIDE,
  topNavColor: '#3e82f7',
  scrollDriverHover: 'rgba(64, 131, 247, 0.68)',
  scrollDriverDisabled: 'rgba(0, 0, 0, 0.62)',
  headerNavColor: '#ffffff',
  mobileNav: false,
  currentTheme: 'light',
  // colors
  colorBlueBase: '#2600E8',
  colorBlueLight: '#b4ccff',
  colorPurpleBase: '#5b13ce',
  colorPurpleLight: '#7950da',
  colorPinkBase: '#fe0085',
  colorMagentaBase: '#c400f0',
  colorCyanBase: '#19f3f5',
  colorPupleBaseLight: '#b1a5e8',
  colorBlueFacebook: '#1877f2',
  // graph multiple colors,
  colorLightRed: '#ea5545',
  colorPinkPale: '#f46a9b',
  colorOrange: '#ef9b20',
  colorOrangePale: '#edbf33',
  colorYellow: '#ede15b',
  colorLightGreen: '#bdcf32',
  colorDarkGreen: '#87bc45',
  colorLightPink: '#FFDDC7',
  colorLightBlue: '#a7d5ed',
  colorDarkBlue: '#27aeef',
  colorDarkPurple: '#b33dc6',
  // chart colors
  regionBarChartBlue: '#345AF1',
  maleAudienceChartOrange: '#FFAA73',
  femaleAudienceChartGreen: '#37D27F'
}

export const PLATEFORM_COLOR = {
  default: 'rgb(52, 90, 241)',
  Facebook: 'rgb(24, 119, 242)',
  Instagram: 'rgb(203, 0, 191)',
  TikTok: 'rgb(12, 11, 11)',
  YouTube: 'rgb(255, 0, 0)',
  Snapchat: 'rgb(255, 252, 0)',
  LinkedIn: 'rgb(0, 65, 149)'
}

export const REPORT_PLATEFORM_COLOR = {
  default: 'rgb(52, 90, 241)',
  Facebook: 'rgb(24, 119, 242)',
  Instagram: 'rgb(203, 0, 191)',
  TikTok: 'rgb(12, 11, 11)',
  YouTube: 'rgb(255, 0, 0)',
  Snapchat: 'rgb(255, 252, 0)',
  LinkedIn: 'rgb(0, 65, 149)'
}

export const PLATFORM_NAMES = {
  fb: 'Facebook',
  FB: 'Facebook',
  Facebook: 'Facebook',

  ig: 'Instagram',
  IG: 'Instagram',
  Instagram: 'Instagram',

  yt: 'YouTube',
  YT: 'YouTube',
  YouTube: 'YouTube',

  sc: 'Snapchat',
  SC: 'Snapchat',
  Snapchat: 'Snapchat',

  tk: 'TikTok',
  TK: 'TikTok',
  TikTok: 'TikTok',

  li: 'LinkedIn',
  LI: 'LinkedIn',
  LinkedIn: 'LinkedIn'
}

export const CATEGORIES_COLOR = {
  27: 'rgba(241, 120, 8, 0.9)', // Sport
  4: 'rgba(0, 255, 209, 0.9)', // History
  8: 'rgba(33, 146, 255, 0.9)', // International
  329098: 'rgba(233, 0, 100, 0.9)', // Child & Family
  2: 'rgba(131, 20, 81, 0.9)', // Society
  42: 'rgba(227, 132, 255, 0.9)', // Economy & finance
  68: 'rgba(56, 229, 77, 0.9)', // Sciences & Technology
  15: 'rgba(21, 198, 77, 0.9)', // Nature & Environment
  19: 'rgba(134, 93, 255, 0.9)', // Viral
  329831: 'rgba(251, 244, 109, 0.9)', // Mode
  329819: 'rgba(251, 244, 109, 0.9)', // Beauty
  329079: 'rgba(251, 180, 84, 0.9)', // Culture & Hobbies
  35: 'rgba(33, 146, 255, 0.9)', // Politic
  329089: 'rgba(114, 191, 80, 0.9)', // Animals
  329103: 'rgba(0, 0, 0, 0.8)', // Feminism
  329113: 'rgba(56, 229, 77, 0.9)', // Health
  329203: 'rgba(246, 55, 236, 0.9)', // Voyage
  329390: 'rgba(184, 134, 11, 0.9)', // Transports & Vehicles
  329404: 'rgba(246, 55, 236, 0.9)', // Cuisine
  329412: 'rgba(64, 224, 208, 0.9)', // Decoration
  329814: 'rgba(0, 191, 255, 0.9)', // Astrology
  329826: 'rgba(218, 165, 32, 0.9)' // Celebrity
}
